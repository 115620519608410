* {
    outline: 0;
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", Confortaa, 'Open Sans', 'Helvetica Neue';
}

html, body, #root {
    position: relative;
}

#politica-de-privacidade {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
}
